import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Footer } from './footer';
import { MainNavbar } from './main-navbar';
import { useAuth } from '../hooks/use-auth';
import { useRouter } from 'next/router';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64,
}));

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { user, loading } = useAuth();
  const redirectURL = '/organisations';
  const router = useRouter();

  useEffect(() => {
    if (!loading && user) {
      if (router.pathname !== '/') {
        router.push(window.location.pathname);
      }

      router.push(redirectURL);
    }
  }, [loading, user, router]);

  return (
    <MainLayoutRoot>
      <MainNavbar />
      {children}
      <Footer />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};
